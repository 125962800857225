import * as React from 'react';

export default function ButtonAppBar() {
    
    function scrollToOverview () {
        window.scrollTo({top: 550, behavior: "smooth"})
    }

    function scrollToRoadmap () {
        window.scrollTo({top: 725, behavior: "smooth"})
    }


    return(
        <div className="AppBar">
            <span className="AppBarComponents" onClick={scrollToOverview}>
            OVERVIEW
            </span>
            <span className="AppBarComponents" onClick={scrollToRoadmap}>
            ROADMAP
            </span>
            <img src={require('./appbarimage.png')}className="fantasylogo"/>
            <a className="AppBarComponents" href='./VRFantasy_Whitepaper.pdf' className="AppBarComponents" download>
            WHITEPAPER
            </a>
            <span className="logocontainer">
                <img className="logos" src={require('./discordlogo.png')}/>
                <img className="logos" src={require('./twitterlogo.png')}/>
                <img className="logos" src={require('./instagramlogo.webp')}/>
            </span>
        </div>

    )
}
