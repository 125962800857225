import * as React from 'react';
import './Tagline.css';

export default function Tagline() {
    return(
        <div className="tagline">
            <span className="logo"><img src={require('./vrlogo.png')} className="vrlogo"/></span>
            <span className="words">BUILDING THE FUTURE OF FANTASY SPORTS</span>
        </div>

    )
}
