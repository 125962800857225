import * as React from 'react';
import "./Roadmap.css"

export default function Roadmap() {

    return(
        <div className="Roadmap">
            <div className="header">
                ROADMAP
            </div>
            <hr className="hr"/>
        </div>

    )
}
