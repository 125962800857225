import * as React from 'react';
import "./Overview.css"

export default function Overview() {

    return(
        <div className="Overview">
            <div className="header">
                OVERVIEW
            </div>
            <hr className="hr"/>
        </div>

    )
}
